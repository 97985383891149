.home-branding-text {
  font-family: sans-serif;
  font-weight: 100;
  font-size: clamp(20pt, 4vw, 40pt);
  color: white;
}

.home-branding-copy {
  font-family: sans-serif;
  font-weight: 100;
  font-size: clamp(8pt, 1.5vw, 10pt);
  color: white;
  text-align: right;
}

.home-reel-div {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: black;
}

.mainMenu-ul {
  display: flex;
  align-items: stretch; /* Default */
  justify-content: space-between;
  width: 90vw;
  left: 5vw;
  margin: 6vh -15px 0 0;
  padding: 0;
  position: absolute;
}

.mainMenu-li {
  display: block;
  flex: 0 1 auto; /* Default */
  list-style-type: none;
}

.product-category-text {
  font-family: sans-serif;
  font-weight: 100;
  color: white;
  font-size: clamp(10pt, 4vw, 30pt);
  cursor: pointer;

  display: inline-block;
  position: relative;
}

.product-category-text:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.product-category-text:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.product-category-text-active {
  border-bottom: solid 1px white;
}

.category-total {
  font-size: clamp(8px, 2vw, 10pt);
  top: 0px;
  position: absolute;
  padding-left: 5px;
}

.home-overlay-text {
  color: white;
  position: absolute;
  z-index: 7;
  top: 45%;
  font-size: clamp(8pt, 3vw, 18pt);
  width: 100%;
  text-align: center;
}

.square {
  position: relative;
  flex-basis: calc(33.333% - 10px);
  margin: 2px;
  box-sizing: border-box;
  cursor: pointer;
}

.square::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.square .content {
  background-color: black;
  position: absolute;
  top: 0; left: 0;
  height: 100%;
  width: 100%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* .content{
  transition: background-size 0.3s;
} */

.client-div-mobile {
  position: sticky;
  top: 10vh;
  z-index: 8;
  padding-bottom: 10px;
  z-index: 9999;
}

.client-list-text-mobile {
  font-family: sans-serif;
  font-weight: 100;
  color: white;
  cursor: pointer;
  font-size: 10pt;

  /* display: inline; */
  position: relative;
}

.client-mobile-header-text {
  font-family: sans-serif;
  font-weight: 100;
  color: white;
  cursor: pointer;
  font-size: 10pt;
  margin-right: 15px;
  /* line-height: 0.8; */

  display: inline-block;
  position: relative;
}

.client-list-text {
  font-family: sans-serif;
  font-weight: 100;
  color: white;
  cursor: pointer;
  font-size: 10pt;
  margin-right: 15px;
  /* line-height: 0.8; */

  display: inline-block;
  position: relative;
}

.client-list-text:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.client-list-text:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.scroller {
  height: 300px;
  scroll-snap-type: y mandatory;
}

.scroller section {
  scroll-snap-align: start;
}

.image-modal-div {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: rgb(0, 0, 0, 0.8);
}

.close-modal-icon {
  height: 30px;
  margin-top: 10px;
  margin-left: 10px;
  cursor: pointer;
  transition: transform 0.5s;
}

.close-modal-icon:hover {
  transform: rotate(90deg);
}

.about-body {
  font-family: sans-serif;
  font-size: 15pt;
  font-weight: 100;
  color: white;
}

.about-body-href {
  font-family: sans-serif;
  font-size: 15pt;
  font-weight: 100;
  color: white;
}

.about-body-href:hover {
  color: grey;
  text-decoration: none;
}

.about-header {
  font-family: sans-serif;
  font-size: 15pt;
  font-weight: 700;
  color: white;
}

.client-div {
  height: 75vh;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}