.landing {
    --play-button: none;
    --seek-live-button: none;
    --seek-backward-button: none;
    --seek-forward-button: none;
    --mute-button: none;
    --captions-button: none;
    --airplay-button: none;
    --pip-button: none;
    --fullscreen-button: none;
    --cast-button: none;
    --playback-rate-button: none;
    --volume-range: none;
    --time-range: none;
    --time-display: none;
    --duration-display: none;
    --controls: none;
    --playback-rate-button: none;
    cursor: pointer;
} 

.project {
    --seek-live-button: none;
    --playback-rate-button: none;
    --cast-button: none;
    --airplay-button: none;
    --seek-backward-button: none;
    --seek-forward-button: none;
    --volume-range: none;
    --time-display: none;
    --controls: none;
    cursor: pointer;
    height: 80vh;
}