.header-title-text {
    font-family: sans-serif;
    font-size: clamp(20pt, 4vw, 40pt);
    font-weight: 100;
    color: white;
}

.project-ul {
    display: flex;
    align-items: stretch; /* Default */
    justify-content: space-between;
    width: 100%;
    left: 0vw;
    margin: 0 -15px 0 0;
    padding: 0;
    position: relative;
  }

  .project-li {
    display: block;
    flex: 0 1 auto; /* Default */
    list-style-type: none;
  }

  .body-copy {
    font-family: sans-serif;
    color: white;
    font-size: clamp(10pt, 4vw, 15pt);
    font-weight: 100;
  }

  .team {
    line-height: 1;
  }

  .dot {
    height: 7px;
    width: 7px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 8px;
    margin-bottom: 2px;
  }