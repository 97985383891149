.formDiv {
    width: 100%;
    text-align: center;
    top: 10vh;
}

.mail-header {
    font-family: sans-serif;
    font-weight: 100;
    font-size: clamp(10pt, 5vw, 15pt);
    color: white;
  }

  .mail-input {
    background: transparent; 
    border: 1px white solid; 
    color: White;
    padding-left: 10px;
    margin-top: 5px;
    width: 100%;
  }

  .mail-input-message {
    background: transparent; 
    border: 1px white solid; 
    color: White;
    padding-left: 10px;
    margin-top: 5px;
    width: 100%;
    height: 20vh;
    box-sizing: border-box;
    resize: vertical;
  }

  .mail-button {
    background: transparent; 
    border: none; 
    font-family: sans-serif;
    color: white;
    padding-top: 10px;
  }

  .inputTextStyling {
    display: block;
  }

  .header-text {
    color: white;
    text-align: left;
    padding-top: 10px;
  }